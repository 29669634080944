
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { DxCheckBox } from "devextreme-vue/check-box";
import { SendMarketRequestType } from "@/models/request/MarketRequest";
import BaseButton from "@/components/BaseButton";
import MessageSendPopup from "@/components/MessageSendPopup";
import SendMessageToUsersPopup from "@/components/SendMessageToUsersPopup";
import {
    MarketRequest,
    MarketRequestState
} from "@/models/request/MarketRequest";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { isUserInRoles } from "@/services/userUtils";

@Component({
    components: {
        DxCheckBox,
        BaseButton,
        MessageSendPopup,
        SendMessageToUsersPopup
    },
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest,
            isLoading: MarketRequestGetters.IsLoading
        })
    }
})
export default class RequestInputControls extends Vue {
    protected submitConfirmation = false;
    protected sendPopupVisible = false;
    protected sendMessageToUsersPopupVisible = false;

    protected isLoading!: boolean;

    protected sendTypes = SendMarketRequestType;
    protected currentSendType = SendMarketRequestType.None;

    protected readonly selectedMarketRequest!: MarketRequest | null;

    get isMarketOpenOrUpdateRequested(): boolean {
        return (
            this.selectedMarketRequest?.state === MarketRequestState.Open ||
            this.selectedMarketRequest?.state ===
                MarketRequestState.UpdateRequested
        );
    }

    get isCommittedMarketRequest(): boolean {
        return (
            this.selectedMarketRequest?.state === MarketRequestState.Committed
        );
    }

    get isApprovedMarketRequest(): boolean {
        return (
            this.selectedMarketRequest?.state === MarketRequestState.Approved
        );
    }

    get isMarketUser(): boolean {
        return isUserInRoles(["MSU", "MU"]);
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    showSendPopup(type: SendMarketRequestType): void {
        this.sendPopupVisible = true;
        this.currentSendType = type;
    }

    showSendMessagePopup(): void {
        this.sendMessageToUsersPopupVisible = true;
    }
}
